<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-card
      elevation="2"
    >
      <v-card-title>
        {{ $t('custom.appointment.inquiry') }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <h3 class="font-weight-bold">
                {{ $t('custom.appointment.inquiry_subtitle') }}
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <app-countries
              v-model="form.country_id"
              cols="12"
              name="country_id"
              required
              sm="6"
              :suffix="countryKey"
              @set-items="countries = $event"
            />
            <app-number-input
              v-model="form.mobile"
              cols="12"
              name="mobile"
              required
              sm="6"
              :prefix="countryKey"
              placeholder="5xxxxxxxx"
              persistent-placeholder
            />
          </v-row>
          <v-row>
            <v-col
              v-if="message"
              cols="12"
            >
              <h3>{{ message }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="items.length>0"
              cols="12"
            >
              <h3>{{ parseAttribute('available_appointments') }}</h3>
            </v-col>
            <v-col
              v-for="(e,i) in items"
              :key="i.toString()"
              cols="12"
            >
              <slide-fade-transition>
                <v-sheet
                  rounded
                  elevation="2"
                  class="py-2 px-4"
                >
                  <v-row
                    dense
                    justify="space-between"
                    class="font-weight-bold text-body-1"
                  >
                    <v-col cols="auto">
                      <span>{{ parseAttribute('company_id') }}: {{ e.company_id_to_string }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <span>{{ parseAttribute('appointment_date_id') }}: {{ e.date_to_string }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <span>{{ parseAttribute('appointment_time_id') }}: {{ e.time }}</span>
                    </v-col>
                  </v-row>
                </v-sheet>
              </slide-fade-transition>
            </v-col>
          </v-row>
          <!--          <v-row>
            <v-col cols="auto">
              <app-btn
                :loading="loading"
                :disabled="disabled"
                @click="submit"
              >
                <v-icon left>
                  search
                </v-icon>
                {{ $t('search') }}
              </app-btn>
            </v-col>
          </v-row>-->
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <app-btn
          :loading="loading"
          :disabled="disabled"
          @click="submit"
        >
          <v-icon left>
            search
          </v-icon>
          {{ $t('search') }}
        </app-btn>
        <v-spacer />
        <app-btn
          :to="route(APP_ROUTES.appointment.newForm)"
        >
          {{ $t('custom.appointment.new') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import { MetaInfoMixin } from '@mixins'
import { isDevelopment } from '@helpers/Tools'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'InquiryAppointment',
  components: { SlideFadeTransition },
  mixins: [MetaInfoMixin],
  data () {
    return {
      loading: !1,
      countries: [],
      items: [],
      message: null,
      form: {
        country_id: isDevelopment ? 1 : null,
        mobile: isDevelopment ? '0590470092' : null
      }
    }
  },
  computed: {
    countryKey () {
      return this.countries.find(c => c.id === this.form.country_id)?.key
    },
    disabled () {
      return !(this.form.country_id && this.form.mobile)
    }
  },
  mounted () {

  },
  methods: {
    async submit () {
      if (this.loading) return
      this.loading = !0
      this.items = []
      this.message = null
      try {
        const { _data, _message } = await this.apiService.appointment.inquiry(this.form)
        this.items = _data || []
        this.message = _message || null
      } catch (e) {
        const { _message } = e || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
